.projects {
	background-color: #000;
	min-height: 100vh;
}

.projects h1 {
	text-align: center;
	color: #0f8;
	padding-top: 20px;
	font-family: Raleway !important;
}

.projects-cards {
	padding: 1rem;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(23%, 1fr));
	grid-auto-rows: auto;
	grid-gap: 1rem;
}

@media screen and (max-width: 500px) {
	.projects-cards {
		padding: 1rem;
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
		grid-auto-rows: auto;
		grid-gap: 1rem;
	}
}

@media screen and (min-width: 501px) {
	.projects-cards {
		padding: 1rem;
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(45%, 1fr));
		grid-auto-rows: auto;
		grid-gap: 1rem;
	}
}

@media screen and (min-width: 768px) {
	.projects-cards {
		padding: 1rem;
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
		grid-auto-rows: auto;
		grid-gap: 1rem;
	}
}

@media screen and (min-width: 992px) {
	.projects-cards {
		padding: 1rem;
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(23%, 1fr));
		grid-auto-rows: auto;
		grid-gap: 1rem;
	}
}

