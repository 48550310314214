.project-card {
	background-color: white;
  border-radius: 0.25rem;
  box-shadow: 0 20px 40px -14px rgba(0,0,0,0.25);
  overflow: hidden;
}

.project-card:hover .project-card-image{
	filter: contrast(100%);
}

.project-card-content {
	display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding: 1rem;
}

.project-card-image {
	background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  filter: contrast(70%);
  /* filter: saturate(180%); */
  overflow: hidden;
  position: relative;
  transition: filter 0.5s cubic-bezier(.43,.41,.22,.91);
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}

.project-card-image:before {
  content: "";
  display: block;
  padding-top: 56.25%; /*16:9 aspect ratio*/
}

@media(min-width: 40rem) {
  .project-card-image:before {
    padding-top: 66.6%; /*3:2 aspect ratio*/
  }
}

.project-card-title {
	color: #696969;
  font-size: 1.2rem;
  min-height: 3.6rem;
  font-weight: 300;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: center;
}

.project-card-text {
	flex: 1 1 auto;
  font-size: 0.875rem;
  min-height: 3rem;
  padding-top: 20px;
  line-height: 1.5;
  margin-bottom: 1.25rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.project-card-buttons {
  display: inline-grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.25rem;
}

.project-card-button {
	background-color: #333;
  border-radius: 0.4rem;
  border: 1px solid #ccc;
  font-size: 0.875rem;
  color: #ccc;
  padding: 0.5rem;
  display: inline-block;
  width: 100%;
  transition: all 0.3s ease-in-out;
}

.project-card-button:hover {
  background-color: #ccc;
  color: #555;
}